<template>
	<div>
		<button
			v-if="isMobile"
			class="relative inline-block transition-all duration-100 border border-gray-300 border-solid rounded-full shadow-sm outline-none h-9 w-9 hover:shadow ring-offset-2 focus:ring focus:ring-green-300"
			@click="toggleCarts"
		>
			<img
				src="@/assets/icons/cart.svg"
				alt="Go to cart"
				height="20"
				width="20"
				class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
			>
			<template v-if="cartHasItems">
				<span
					:class="[
						'absolute text-xs text-white block w-4 h-4 bg-red-500 rounded-full -top-1.5 -right-1 ring-white ring-2',
						{ 'ring-red-500': hasDoubleDigitCartItemsCount },
						{ 'animate-ping': showPingAnimation },
					]"
				/>
				<span
					:class="[
						'absolute text-xs text-white w-4 h-4 bg-red-500 rounded-full -top-1.5 -right-1 ring-white ring-2',
						{ 'ring-red-500': hasDoubleDigitCartItemsCount }]"
				>
					{{ formattedItemCount }}
				</span>
			</template>
		</button>
		<WwDropdown
			v-else
			:menu-items="mappedCarts"
			class="relative inline-block transition-all duration-100 border border-gray-300 border-solid rounded-full shadow-sm outline-none h-9 w-9 hover:shadow ring-offset-2 focus:ring focus:ring-green-300"
			button-id="cart-button"
			content-width="w-96"
			show-top-arrow
			:force-open-position="RIGHT"
			:x-offset-override="1"
			:show-close-button="true"
		>
			<template #button="{ clicked }">
				<button
					class="my-4"
					@click="toggleCarts(clicked)"
				>
					<img
						src="@/assets/icons/cart.svg"
						alt="Go to cart"
						height="20"
						width="20"
						class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
					>
					<template v-if="cartHasItems">
						<span
							:class="[
								'absolute text-xs text-white block w-4 h-4 bg-red-500 rounded-full -top-1.5 -right-1 ring-white ring-2',
								{ 'ring-red-500': hasDoubleDigitCartItemsCount },
								{ 'animate-ping': showPingAnimation },
							]"
						/>
						<span
							:class="[
								'absolute text-xs text-white w-4 h-4 bg-red-500 rounded-full -top-1.5 -right-1 ring-white ring-2',
								{ 'ring-red-500': hasDoubleDigitCartItemsCount }]"
						>
							{{ formattedItemCount }}
						</span>
					</template>
				</button>
			</template>
		</WwDropdown>
		<CartSlideOver :cart="temporaryCart" />
		<CartsSlider
			v-if="carts"
			:carts="carts"
		/>
		<DeleteFromCartModal />
		<AddToCartModal
			:product="lastAddedItem"
			:subtotal="subtotal"
			:carts="carts"
		/>
	</div>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import { RIGHT } from '@/constants/positions.js'
import { CARTS_SLIDE_OVER } from '@/constants/slideOver/names.js'
import isCheckoutPage from '@/utils/cart/is-checkout-page.js'

export default {
	components: {
		WwDropdown: () => import('@/components/UI/WwDropdown.vue'),
		CartsSlider: () => import('@/components/cart/CartsSlider.vue'),
		CartSlideOver: () => import('@/components/UI/slideOvers/CartSlideOver.vue'),
		DeleteFromCartModal: () => import('@/components/UI/modals/DeleteFromCartModal.vue'),
		AddToCartModal: () => import('@/components/UI/modals/AddToCartModal.vue')
	},
	props: {
		cartItemsCount: {
			type: Number,
			default: 0
		},
		carts: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			showPingAnimation: false,
			CARTS_SLIDE_OVER,
			RIGHT
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		...mapGetters('cart', [ 'cart' ]),
		temporaryCart() {
			return this.cart?.temporaryCart || {}
		},
		cartHasItems() {
			return this.cartItemsCount > 0
		},
		hasDoubleDigitCartItemsCount() {
			return this.cartItemsCount > 9
		},
		formattedItemCount() {
			return this.hasDoubleDigitCartItemsCount ? '9+' : this.cartItemsCount
		},
		lastAddedItem() {
			return this.cart?.items.slice(-1)[0] || {}
		},
		subtotal() {
			const decimalPrice = this.cart?.subtotal?.toFixed(2) || '0'
			if (this.cart.subtotal !== undefined) {
				return decimalPrice.includes('.00') ? decimalPrice.replace('.00', '') : decimalPrice
			} else {
				return '0'
			}
		},
		mappedCarts() {
			return this.carts?.map((cart, index) => {
				return {
					...cart,
					type: 'cartCard',
					total: cart.total,
					index
				}
			}) || []
		}
	},
	watch: {
		cartItemsCount: {
			immediate: false,
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					this.showPingAnimation = true
					setTimeout(() => { this.showPingAnimation = false }, 3000)
				}
			}
		},
		$route: {
			immediate: true,
			deep: true,
			handler(newVal) {
				if (isCheckoutPage(newVal)) this.closeSlideOver()
			}
		}
	},
	methods: {
		...mapMutations('slideOver', [ 'showSlideOver', 'closeSlideOver' ]),
		toggleCarts(clicked) {
			if (clicked && this.cartItemsCount && !this.isMobile) {
				clicked()
			} else {
				this.showSlideOver(CARTS_SLIDE_OVER)
			}
		}
	}
}
</script>
