import axios from 'axios'

import { API_SERVER } from '@/constants/index.js'
import { logError } from '@/utils/error-handling.js'

let cancel
const CancelToken = axios.CancelToken

const apiServer = API_SERVER || '' // if a server is not defined, assume dev/prod server env

export default {
	endPoint: apiServer + '/search.php?ajaxData=true',
	performSearch: async function (data = {}) {
		let searchResults
		if (data.searchTerm.length < 2) return

		if (cancel !== undefined) {
			cancel('Cancel')
		}
		try {
			searchResults = await axios.get(`${this.endPoint}&search=${data.searchTerm}`, {
				cancelToken: new CancelToken(function executor(c) {
					cancel = c
				})
			})
		} catch (e) {
			if (e.message !== 'Cancel') {
				logError(e)
			}
		}
		return (searchResults && searchResults.data ? searchResults.data : {})
	}
}
