export const LOGO_FOOTER_CLICK = {
	event: {
		name: 'ww-logo-footer',
		params: {
			event_category: 'engagement',
			event_label: 'Footer WW Logo Clicked',
			value: 1
		}
	}
}

export const LOGO_HEADER_CLICK = {
	event: {
		name: 'ww-logo-header',
		params: {
			event_category: 'engagement',
			event_label: 'Header WW Logo Clicked',
			value: 1
		}
	}
}
