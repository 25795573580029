<template>
	<div class="flex flex-col flex-grow w-full min-h-screen">
		<div
			:class="[
				'sticky top-0 z-30 w-full',
				isLoginPage ? 'md:bg-white' : 'border-b border bg-white'
			]"
		>
			<AppHeader
				v-if="showAppHeader"
				:full-width="fullWidthHeader"
			/>
			<div
				v-if="fullWidthHeader"
				class="items-center h-24 md:h-auto"
			>
				<slot name="mobile-map-controls" />
			</div>
		</div>
		<div v-if="$store.state.errorCode == 404">
			<Error404 />
		</div>
		<Transition
			v-else
			name="fade"
			mode="out-in"
		>
			<slot />
		</Transition>
		<Transition :name="isMobile ? 'slide-bottom' : 'disabled'">
			<AppFooter
				v-if="showFooter"
				class="z-20"
			/>
		</Transition>
		<LoginModal v-if="isMounted" />
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import AppHeader from '@/components/singleUse/AppHeader.vue'

export default {
	components: {
		AppHeader,
		AppFooter: () => import('@/components/singleUse/AppFooter.vue'),
		LoginModal: () => import('@/components/UI/modals/LoginModal.vue'),
		Error404: () => import('@/components/error/404.vue')
	},
	props: {
		fullWidthHeader: {
			type: Boolean,
			default: false
		},
		hideFooter: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isMounted: false
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		showFooter() {
			if (this.hideFooter) return false
			if (this.$route.meta && this.$route.meta.noFooter) return false // city map page no footer
			if (typeof window === 'undefined') return false
			if (window.matchMedia('(display-mode: standalone)').matches) return false // generic detect pwa launch
			if (window.navigator.standalone === true) return false // safari detect pwa launch

			return this.isMounted
		},
		showAppHeader() {
			return this.$route.name !== 'businessCart' && this.$route.name !== 'chainListingCart'
		},
		isLoginPage() {
			return this.$route.name === 'login'
		}
	},
	mounted() {
		this.isMounted = true
	}
}
</script>
