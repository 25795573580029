<template>
	<img
		:src="logoSrc"
		alt="Where's Weed"
		:width="width"
		:height="height"
	>
</template>

<script async>
const SPECIAL_LOGOS = {
	june: 'pride'
}
const MONTHS = [
	'january',
	'february',
	'march',
	'april',
	'may',
	'june',
	'july',
	'august',
	'september',
	'october',
	'november',
	'december'
]

export default {
	props: {
		height: {
			type: Number,
			default: 30
		},
		full: {
			type: Boolean,
			default: false
		},
		size: {
			type: String,
			default: 'default'
		},
		white: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			availableWidths: {
				full: 180,
				half: 170,
				default: 35
			},
			ext: 'svg'
		}
	},
	computed: {
		width() {
			if (this.size !== 'default') {
				switch (this.size) {
					case 'full':
						return this.availableWidths.full
					case 'half':
						return this.availableWidths.half
					default:
						return this.availableWidths.default
				}
			}
			return this.full ? this.availableWidths.full : this.availableWidths.default
		},
		logoFile() {
			let filePath = ''

			if (this.specialLogoFolder) {
				filePath = `${this.specialLogoFolder}/`
			}
			filePath += this.full ? 'logo-full' : 'logo'
			if (this.white) {
				filePath += '-white'
			}

			return filePath
		},
		logoSrc() {
			return require(`@/assets/site-logos/${this.logoFile}.${this.ext}`)
		},
		currentMonth() {
			const monthIndex = new Date().getMonth()
			return MONTHS[monthIndex]
		},
		specialLogoFolder() {
			return SPECIAL_LOGOS[this.currentMonth] || false
		}
	}
}
</script>
