<template>
	<div
		class="flex flex-wrap items-center content-start justify-between px-3 py-2 mx-auto lg:content-center lg:h-14"
		:class="[
			{'max-w-6xl': !fullWidth},
			isSearchOpen && !hideLogoAndButtons ? 'h-36' : 'md:h-24',
			hideLogoAndButtons && !isSearchOpen ? ' md:bg-white h-16 md:h-24' : 'md:bg-white',
			hideLogoAndButtons && isSearchOpen ? 'bg-white h-28 md:h-24' : 'md:bg-white'
		]"
	>
		<div
			v-track-event.click="LOGO_HEADER_CLICK"
			class="self-start lg:h-auto lg:self-center"
			:class="{'hidden md:flex': hideLogoAndButtons}"
		>
			<router-link
				to="/"
				class="my-1.5 lg:my-0"
			>
				<WwLogo class="flex sm:hidden" />
				<WwLogo
					full
					class="hidden sm:flex"
				/>
			</router-link>
		</div>
		<div
			:class="[
				hideLogoAndButtons ? 'md:bg-white' : 'bg-white',
				{'bg-white' : hideLogoAndButtons && isSearchOpen },
				'flex flex-wrap items-center self-end order-3 w-full lg:self-center lg:flex-nowrap lg:order-none lg:max-w-[40rem] xl:max-w-2xl'
			]"
		>
			<label
				for="main-search"
				class="w-full pt-1.5 lg:py-0 lg:pr-3"
			>
				<SearchInput
					id="main-search"
					class="relative"
					:class="isMobile ? 'min-w-global-search' : 'min-w-global-search-desktop'"
					:input-id="GLOBAL_SEARCH_ID"
					:exclude-location="true"
					title="Find dispensaries, products, strains and more."
					placeholder-default="Dispensaries, Products, Strains, and more"
					@focus="isSearchFocused = true"
					@blur="isSearchFocused = false"
				/>
			</label>
			<ClientOnly>
				<label
					for="location-search"
					:class="[
						{ 'hidden lg:flex': !isSearchOpen },
						'w-full pt-1.5 lg:py-0'
					]"
				>
					<SearchInput
						id="location-search"
						class="relative lg:block min-w-location-search"
						:class="{ 'hidden': !isSearchOpen }"
						:default-value="locationName"
						:placeholder-default="locationSearchDefaultPlaceholder"
						:location-only="true"
						title="Location."
						@focus="isSearchFocused = true"
						@blur="isSearchFocused = false"
					/>
				</label>
			</ClientOnly>
		</div>
		<div class="flex items-center content-start self-start justify-end h-9 lg:h-auto lg:self-center">
			<WwCartButton
				v-if="isMounted"
				:cart-items-count="cartItemsCount"
				:carts="carts"
				class="pr-3"
			/>
			<div v-if="showProfileDropdown">
				<WwDropdown
					:menu-items="menuItems"
					button-id="login-button"
					content-width="w-36"
					show-top-arrow
					:force-open-position="RIGHT"
					@menu-clicked="handleMenuClick"
				>
					<template #button="{ clicked }">
						<button
							id="menu-button"
							type="button"
							class="flex items-center justify-center text-gray-500 bg-white border-none rounded-full outline-none h-9 w-9 ring-offset-2 focus:ring focus:ring-green-300 focus:border-none"
							aria-expanded="true"
							aria-haspopup="true"
							@click="clicked"
						>
							<WwImage
								:src="profileImage"
								classes="block m-auto rounded-full"
								:height="40"
								:width="40"
								:fallback-image="defaultUserImage"
								:image-path="USER_IMAGES"
								:lazy="false"
								alt="User Thumbnail Image"
							/>
						</button>
					</template>
				</WwDropdown>
			</div>
			<div
				v-else
				:class="{ 'hidden md:flex': hideLogoAndButtons }"
			>
				<router-link
					tag="button"
					:to="{ name: 'login' }"
					class="w-12 py-2 mr-2 text-xs font-bold text-black bg-white border border-gray-300 rounded shadow-sm hover:shadow lg:text-sm"
				>
					Login
				</router-link>
				<router-link
					tag="button"
					:to="{ name: 'register' }"
					active
					class="w-12 py-2 text-xs font-bold text-white bg-green-500 border border-green-500 rounded shadow-sm hover:shadow lg:text-sm"
				>
					Join
				</router-link>
			</div>
		</div>
	</div>
</template>

<script async>
import ClientOnly from 'vue-client-only'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import WwImage from '@/components/multiUse/WwImage.vue'
import WwLogo from '@/components/multiUse/WwLogo.vue'
import SearchInput from '@/components/search/SearchInput.vue'
import WwCartButton from '@/components/UI/WwCartButton.vue'
import WwDropdown from '@/components/UI/WwDropdown.vue'
import { USER_IMAGES } from '@/constants/images/image-folder-paths.js'
import { LOGO_HEADER_CLICK } from '@/constants/logging/clickEvents.js'
import { RIGHT } from '@/constants/positions.js'
import { PRIVATE_ROUTES } from '@/constants/private-routes.js'
import { GLOBAL_SEARCH_ID } from '@/constants/search/inputIds.js'
import { SUCCESS } from '@/constants/toast/type.js'
import { GetCarts } from '@/gql/queries/carts.gql'

let closeSearchTimeout

export default {
	components: {
		ClientOnly,
		WwLogo,
		WwDropdown,
		WwCartButton,
		SearchInput,
		WwImage
	},
	props: {
		fullWidth: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			isSearchFocused: false,
			locationSearchDeafultValue: this.locationName,
			GLOBAL_SEARCH_ID,
			expanded: false,
			defaultUserImage: require('@/assets/img-defaults/default-profile-icon.jpg'),
			LOGO_HEADER_CLICK,
			isMounted: false,
			USER_IMAGES,
			RIGHT
		}
	},
	apollo: {
		carts: {
			query: GetCarts,
			update(data) {
				if (data.carts) {
					return data?.carts
				}
			}
		}
	},
	computed: {
		cartItemsCount () {
			return this.carts?.map(({ items }) =>
				items?.map(({ quantity }) =>
					quantity || 0)?.reduce((a, b) => a + b, 0)
			)?.reduce((a, b) => a + b, 0) || 0
		},
		...mapGetters('auth', [ 'auth', 'isLoggedIn' ]),
		...mapGetters('user', [ 'user' ]),
		...mapGetters('search', [ 'isSearchOpen' ]),
		...mapGetters('business', [ 'businessId' ]),
		...mapState({
			locationName: state => state.location.name
		}),
		locationSearchDefaultPlaceholder() {
			return this.locationName?.length ? this.locationName : 'City or Zipcode'
		},
		profileImage() {
			return this.auth?.profilePicture
		},
		menuItems() {
			const tempMenu = [
				{
					text: 'My Profile',
					value: this.auth?.displayName,
					type: 'router-link',
					to: {
						name: 'userIndex',
						params: { user: this.authRouteParam }
					}
				},
				{
					text: 'My Orders',
					value: 'userPreOrders',
					type: 'router-link',
					to: {
						name: 'userPreOrders',
						params: { user: this.authRouteParam }
					}
				}
			]

			if (this.showMyBusinessOption) {
				tempMenu.push({
					text: 'My Business', value: 'linkToManagerPortal', type: 'link', url: this.auth?.managerPortalUrl
				})
			}

			tempMenu.push({
				text: 'Log out', value: 'handleLogout', type: 'button', class: 'border-gray-300 border-t px-3 py-2'
			})
			return tempMenu
		},
		authRouteParam () {
			return this.auth.username || this.auth.id
		},
		isBusinessOwner() {
			return !!this.auth?.businessOwner
		},
		hasManagerPortalUrl() {
			return !!this.auth?.managerPortalUrl
		},
		showMyBusinessOption() {
			return this.isBusinessOwner && this.hasManagerPortalUrl
		},
		hasAuthData() {
			return this.auth.loggedIn !== null
		},
		isLoggedIn() {
			return this.auth.loggedIn
		},
		showProfileDropdown() {
			return this.isLoggedIn && this.isMounted
		},
		showLoginButtons() {
			return this.hasAuthData && !this.isLoggedIn
		},
		...mapGetters([ 'isMobile' ]),
		hideLogoAndButtons() { // login page hides logo and buttons on mobile
			return this.$route.name === 'login'
		}
	},
	watch: {
		isSearchFocused() {
			if (this.isSearchFocused) {
				this.setSearchIsOpen(true)
				clearTimeout(closeSearchTimeout)
			} else {
				closeSearchTimeout = setTimeout(() => {
					this.setSearchIsOpen(false)
				}, 250)
			}
		}
	},
	mounted() {
		this.isMounted = true
	},
	methods: {
		...mapActions('auth', [ 'logOut' ]),
		...mapMutations('search', [ 'setSearchIsOpen' ]),
		...mapMutations('toast', [ 'showToast' ]),
		async handleLogout() {
			await this.logOut()
			this.$apollo.queries.carts.refetch()
			this.showToast({
				primaryText: 'Successfully logged out',
				type: SUCCESS
			})
			if (PRIVATE_ROUTES.indexOf(this.$route.name) >= 0) {
				this.$router.push({
					name: 'userIndex',
					params: this.$route.params
				})
			}
		},
		handleMenuClick(value, checkboxes) {
			if (!checkboxes) {
				this[value]()
			}
		}
	}
}
</script>

<style scoped>
.min-w-global-search-desktop {
	min-width: 23rem;
}
.min-w-global-search {
	min-width: 18rem;
}
.min-w-location-search {
	min-width: 14rem;
}
</style>
