<template>
	<div class="relative w-full">
		<div class="absolute text-gray-500 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-5">
			<div
				v-if="locationOnly"
				class="w-5 h-5 p-0.5"
			>
				<MapPinIcon />
			</div>
			<div
				v-else
				class="w-5 h-5 p-0.5"
			>
				<MagnifierIcon />
			</div>
		</div>
		<label
			:title="title"
			:for="inputId"
		>
			<input
				:id="inputId"
				ref="searchInput"
				v-model="searchTerm"
				v-track-event:custom.search.keydown="customTracker"
				:placeholder="placeholder"
				type="search"
				autocomplete="off"
				autocorrect="off"
				:autofocus="focusOnLoad"
				class="block w-full text-gray-500 bg-gray-200 border-none rounded-full pl-9 focus:ring focus:ring-green-300 focus:border-none"
				:class="inputClasses"
				@focus="handleInputFocused"
				@blur="handleInputBlurred"
				@keydown="handleInputKeyDown"
			>
		</label>
		<SearchAutocompleter
			v-if="showAutocompleter"
			:class="[{'force-w-screen': showFullWidthSearch}, 'pb-2']"
			:is-loading="isLoading"
			:search-results="searchResults"
			:search-term="searchTerm"
			:location-only="locationOnly"
			@is-loading="updateIsLoading"
			@search-result-clicked="handleSearchResultClicked"
		/>
		<div
			v-if="showAutocompleter && showFullWidthSearch"
			class="absolute z-20 h-screen mt-16 bg-black opacity-80 force-w-screen"
		/>
	</div>
</template>

<script async>
import uniqBy from 'lodash.uniqby'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import searchService from '@/api/public/searchService.js'
import MagnifierIcon from '@/components/icons/MagnifierIcon.vue'
import MapPinIcon from '@/components/icons/MapPinIcon.vue'
import { DOWN_ARROW, ENTER_KEY, UP_ARROW } from '@/constants/key-codes.js'
import { SEARCH_TERM_CUSTOM_EVENT, SEARCH_TERM_CUSTOM_MAP } from '@/constants/logging/customEvents.js'
import { CURRENT_LOCATION, LOCATIONS } from '@/constants/search/resultTypes.js'
import { logError } from '@/utils/error-handling.js'

export default {
	components: {
		MagnifierIcon,
		MapPinIcon,
		SearchAutocompleter: () => import('@/components/search/SearchAutocompleter.vue')
	},
	props: {
		focusOnLoad: {
			type: Boolean,
			default: false
		},
		defaultValue: {
			type: String,
			default: ''
		},
		placeholderDefault: {
			type: String,
			default: ''
		},
		placeholderAlt: {
			type: String,
			default: ''
		},
		locationOnly: {
			type: Boolean,
			default: false
		},
		excludeLocation: {
			type: Boolean,
			default: false
		},
		inputClasses: {
			type: String,
			default: '',
			required: false
		},
		inputId: {
			type: String,
			default: '',
			required: false
		},
		title: {
			type: String,
			default: ''
		}
	},
	emits: [ 'focus', 'blur' ],
	data() {
		return {
			placeholder: '',
			searchTerm: '',
			resultsCache: {},
			highlightedResult: -1,
			showAutocompleter: false,
			isLoading: false,
			loader: {},
			CURRENT_LOCATION,
			LOCATIONS,
			currentLocationOption: {
				type: CURRENT_LOCATION
			},
			searchResults: []
		}
	},
	computed: {
		initialSearchResults() {
			return this.locationOnly ? [ this.currentLocationOption ] : []
		},
		...mapState({
			locationName: state => state.location.name
		}),
		...mapGetters('search', [ 'highlightedIndex', 'highlightedRoute' ]),
		mediaMatch() {
			return this.$store.state.mediaMatch
		},
		showFullWidthSearch() {
			return this.mediaMatch === 'sm' || this.mediaMatch === 'xs' || this.mediaMatch === 'md'
		},
		customTracker() {
			return {
				customMap: SEARCH_TERM_CUSTOM_MAP,
				event: SEARCH_TERM_CUSTOM_EVENT
			}
		}
	},
	watch: {
		searchTerm(newTerm, oldTerm) {
			if (newTerm === this.defaultValue || newTerm === oldTerm) return
			if (newTerm !== '') {
				this.search()
			}
		},
		locationName(newLocation) {
			if (this.locationOnly) {
				this.searchTerm = newLocation
			}
		},
		searchResults() {
			this.resetHighlightedIndex()
			let resultCount = Number(this.searchResults?.length)
			if (isNaN(resultCount)) resultCount = 0
			this.setIndexCount(resultCount)
		}
	},
	mounted() {
		this.searchResults = this.locationOnly ? [ this.currentLocationOption ] : []
		this.placeholder = this.placeholderDefault
		this.searchTerm = this.defaultValue
	},
	methods: {
		...mapActions('search', [
			'incrementHighlightedIndex',
			'decrementHighlightedIndex',
			'setIndexCount',
			'resetHighlightedIndex',
			'setSearchTerm'
		]),
		...mapMutations('toast', [ 'showToast' ]),
		updateIsLoading(isLoading) {
			this.isLoading = isLoading
		},
		handleInputFocused() {
			this.showAutocompleter = true
			this.placeholder = this.placeholderAlt
			this.search()
			this.$refs.searchInput.select()
			this.$emit('focus')
		},
		handleInputBlurred() {
			this.placeholder = this.placeholderDefault
			setTimeout(() => {
				this.searchResults = this.initialSearchResults
				this.showAutocompleter = false
			}, 250)
			this.$emit('blur')
		},
		clearHighlightedResult() {
			this.highlightedResult = -1
		},
		restoreResultsFromCache() {
			this.searchResults = this.resultsCache[this.searchTerm]
		},
		cacheResults() {
			this.resultsCache[this.searchTerm] = this.searchResults
		},
		parseSearchResults(results) {
			for (const key in results.data) {
				if (results.data[key].type !== LOCATIONS && this.locationOnly) continue
				if (results.data[key].type === LOCATIONS && this.excludeLocation) continue
				if (Array.isArray(results.data[key].data)) {
					const cappedResults = results.data[key].data.slice(0, 7)
					this.populateSearchResults(cappedResults)
					this.removeDuplicateResults(results.data[key].type)
				}
				this.cacheResults()
			}
		},
		populateSearchResults(cappedResults) {
			if (this.locationOnly) {
				this.searchResults = [ this.currentLocationOption, ...this.searchResults, ...cappedResults ]
			} else {
				this.searchResults = [ ...this.searchResults, ...cappedResults ]
			}
		},
		removeDuplicateResults(resultType) {
			if (resultType === LOCATIONS) {
				this.searchResults = uniqBy(this.searchResults, 'name')
			}
		},
		async fetchSearchResults() {
			this.isLoading = true
			const results = await searchService.performSearch({ searchTerm: this.searchTerm })
			this.searchResults = this.initialSearchResults
			if (results && results.data) {
				this.parseSearchResults(results)
			}
			this.isLoading = false
		},
		async search() {
			if (!this.searchTerm) return
			this.clearHighlightedResult()

			try {
				if (this.resultsCache[this.searchTerm]) {
					this.restoreResultsFromCache()
				} else {
					setTimeout(this.fetchSearchResults, 250)
				}
			} catch (e) {
				logError(e)
			}
		},
		handleInputEnterKeyPress(event) {
			this.setSearchTerm(this.searchTerm)
			event.target.blur()
			if (this.highlightedIndex > -1) {
				if (this.highlightedRoute !== this.$route.fullPath) {
					this.$router.push(this.highlightedRoute)
				}
				this.resetHighlightedIndex()
			} else {
				try {
					this.$router.push({ name: 'search', params: { searchTerm: this.searchTerm } })
				} catch (e) {
					logError(e)
				}
			}
		},
		checkBoundsOfResultsList() {
			if (this.searchResults.data) {
				if (this.highlightedResult > this.searchResults.data.length) {
					this.highlightedResult = 0
				} else if (this.highlightedResult < -1) {
					this.highlightedResult = this.searchResults.data.length
				}
			}
		},
		async handleInputKeyDown(event) {
			switch (event.keyCode) {
				case DOWN_ARROW:
					this.incrementHighlightedIndex()
					break
				case UP_ARROW:
					this.decrementHighlightedIndex()
					break
				case ENTER_KEY:
					this.handleInputEnterKeyPress(event)
				default:
					break
			}

			this.checkBoundsOfResultsList()
		},
		handleSearchResultClicked() {
			this.setSearchTerm(this.searchTerm)
		}
	}
}
</script>

<style lang="scss" scoped>
.force-w-screen {
	width: 100vw;
	margin-left: calc(-50vw + 50%);
	margin-right: calc(-50vw + 50%);
}

.autocompleter::-webkit-scrollbar {
	background-color: #fff;
	width: 16px;
	border-radius: 16px;
}

/* background of the scrollbar except button or resizer */
.autocompleter::-webkit-scrollbar-track {
	background-color: #fff;
	border-radius: 16px;
}
.autocompleter::-webkit-scrollbar-track:hover {
	background-color: #f4f4f4
}

/* scrollbar itself */
.autocompleter::-webkit-scrollbar-thumb {
	background-color: #babac0;
	border-radius: 16px;
	border: 5px solid #fff
}
.autocompleter::-webkit-scrollbar-thumb:hover {
	background-color: #a0a0a5;
	border: 4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
.autocompleter::-webkit-scrollbar-button { display: none }

</style>
