export const SEARCH_TERM_CUSTOM_MAP = {
	customSearchDimension: 'searchTerm'
}

export const SEARCH_TERM_CUSTOM_EVENT = {
	name: 'search',
	params: {
		paramName: 'searchTerm'
	}
}

export const PILL_NAV_CUSTOM_MAP = {
	customPillNavDimension: 'pillName'
}

export const DAB_DAY_CUSTOM_MAP = {
	customDabDayDimension: 'dabDay'
}
